<template>
  <div class="grid grid-cols-1 md:grid-cols-1 items-center justify-between text-left mt-6 overflow-hidden pb-12">
    <div
      v-if="loading"
      class="text-center"
    >
      Loading...
    </div>
    <div v-else-if="token !== null">
      <div class="grid lg:grid-cols-2 sm:grid-cols-1 gap-6">
        <img
          class="m-auto"
          :class="{'vibrate-1': upgrading, 'bounce-in-top': !upgrading}"
          :src="token.metadata.animatedUri ? token.metadata.animatedUri : token.metadata.artifactUri"
        >
        <div class="text-center nes-container is-rounded bg-brown-dark p-5">
          <h1 class="text-2xl text-center">
            {{ token.metadata.name }}<br>
            <span class="text-xl text-gray-600">({{ token.rarity }})</span>
          </h1>
          <div
            v-if="token.stats"
            class="mt-5 p-2 border-2 border-dashed border-black text-base sm:text-xl"
          >
            Global Stats<br>
            <span class="inline text-center text-xs">
              Last refresh: {{ statsTimestamp.fromNow() }} <button
                class="text-blue-link hover:text-blue-hov"
                @click="refresh"
              ><fa 
                icon="sync"
                class="h-5 w-5 align-bottom"
              /></button>
            </span>
            <div class="grid grid-cols-2 text-gray-600">
              <div class="text-right">
                cap:
              </div>
              <div class="text-left text-green-800">
                {{ token.stats.cap }}
              </div>
            </div>
            <div class="grid grid-cols-2 text-gray-600">
              <div class="text-right">
                minted:
              </div>
              <div class="text-left text-green-800">
                {{ token.stats.minted }}
              </div>
            </div>
            <div class="grid grid-cols-2 text-gray-600">
              <div class="text-right">
                burned:
              </div>
              <div class="text-left text-green-800">
                {{ token.stats.burned }}
              </div>
            </div>
            <div class="grid grid-cols-2 text-gray-600">
              <div class="text-right">
                trades:
              </div>
              <div class="text-left text-green-800">
                {{ token.stats.trades ? token.stats.trades : '-' }}
              </div>
            </div>
            <div class="grid grid-cols-2 text-gray-600">
              <div class="text-right">
                sales:
              </div>
              <div class="text-left text-green-800">
                {{ token.stats.sales ? token.stats.sales : '-' }}
              </div>
            </div>
            <div class="grid grid-cols-2 text-gray-600">
              <div class="text-right">
                high:
              </div>
              <div class="text-left text-green-800">
                {{ token.stats.high ? state.convertFromMutez(token.stats.high) + 'tez' : '-' }}
              </div>
            </div>
            <div class="grid grid-cols-2 text-gray-600">
              <div class="text-right">
                low:
              </div>
              <div class="text-left text-green-800">
                {{ token.stats.low ? state.convertFromMutez(token.stats.low) + 'tez' : '-' }}
              </div>
            </div>
            <div class="grid grid-cols-2 text-gray-600">
              <div class="text-right">
                last:
              </div>
              <div class="text-left text-green-800">
                {{ token.stats.last ? state.convertFromMutez(token.stats.last) + 'tez' : '-' }}
              </div>
            </div>
          </div>
          <div
            v-if="token.rarity !== 'unique'"
            class="mt-5 p-2 border-2 border-dashed border-black text-xl"
          >
            Upgrade Requirements
            <div class="text-gray-900">
              <img
                width="20"
                class="inline mr-2 mb-2"
                src="../assets/flame.png"
              >{{ token.upgradeReq }} {{ token.rarity }}s
            </div>
            <div class="squared-full text-green-800">
              <fa 
                icon="coins" 
                class="h-5 w-5 mr-1"
              />{{ state.convertFromMutez(token.upgradeCost) }}<span class="text-gray-600">tez</span>
            </div>
            <span class="text-base text-gray-900">=1x {{ token.upgradeTo }}</span>
          </div>
          <div
            v-if="state.userAddress"
            class="mt-5 p-2 border-2 border-dashed border-black sm:text-xl"
          >
            <div v-if="!upgrading">
              Your Balance: <span class="text-green-800">{{ token.balance }}</span>
              <br>
              <div v-if="token.balance >= token.upgradeReq && token.rarity !== 'unique'">
                upgrade to {{ token.upgradeTo }}?
                <div
                  class="grid sm:grid-cols-2 gap-6 p-4 text-gray-600"
                >
                  <div>
                    <button
                      class="text-sm uppercase p-4 nes-btn is-primary w-full"
                      @click="upgrade(token)"
                    >
                      Upgrade Once
                    </button>
                  </div>
                  <div>
                    <button
                      class="text-sm uppercase p-4 nes-btn w-full"
                      @click="upgradeBatch(token)"
                    >
                      Upgrade All
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <span v-else>
              <fa 
                icon="cog" 
                class="animate-spin h-5 w-5 mr-3"
              />
              Upgrading... Pls wait up to 1 min for block confirmation.
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="text-center"
    >
      POTUS Not Found
    </div>
  </div>
</template>

<script>

import { OpKind } from '@taquito/taquito'
import axios from 'axios'
import { find, findIndex } from 'lodash'
import moment from 'moment'

export default {
  name: 'Potus',
  props: {
  },
  data () {
    return {
      state: this.$root.$data.state,
      potusId: null,
      token: null,
      loading: true,
      upgrading: false,
    }
  },
  computed: {
    statsTimestamp () {
      return this.state.statsTimestamp
    },
  },
  watch: {
    $route() {
      this.getId()
    },
  },
  async mounted() {
    this.state.log(this.$route.params)
    this.getId()
  },
  methods: {
    async getId () {
      try{
        if (this.$route.params.potusId) {
          this.potusId = parseInt(this.$route.params.potusId)
          if (this.state.allTokens.length === 0) {
            this.loading = true
            await new Promise((resolve) => setTimeout(resolve, 500))
            return this.getId()
          }
          this.token = find(this.state.allTokens, tok=>{return tok.tokenId === this.potusId})

          // this.state.log()
          document.getElementById("ogUrl").setAttribute('content', `/potus/${this.potusId}`)
          document.getElementById("ogImg").setAttribute('content', `${this.token.metadata.thumbnailUri}`)

          this.token.balance = 0
          if(this.state.userAddress) {
            this.getBalance()
          }

          const seriesId = this.potusId % 1000
          const series = find(this.state.stats.series, s => {return s.seriesId === seriesId})
          if(series) {
            this.token.stats = find(series.levels, l => {return l.tokenId === this.potusId})
          }
          this.state.log(this.token)
          this.loading = false
        } else {
          this.potusId = null
          this.token = null
        }
      } catch (error) {
        this.loading = false
      }
    },
    async refresh () {
      this.loading = true
      this.token = null
      const statsResp = await axios({
        url:'/api/statsv2', 
      })
      this.state.stats = statsResp.data.stats
      this.state.statsTimestamp = moment()
      localStorage.setItem('stats', JSON.stringify({ stats: statsResp.data.stats, timestamp: new Date().toISOString() }))
      this.getId()
    },
    async getBalance() {
      if (this.state.myTokens.length === 0) {
        await new Promise((resolve) => setTimeout(resolve, 500))
        return this.getBalance()
      }
      this.state.log('get balance', this.state.myTokens, this.potusId)
      const mt = find(this.state.myTokens, tok=>{return tok.tokenId === this.potusId})
      if (mt) {
        this.token.balance = mt.balance
      }
    },
    async upgrade (token) {
      if(this.state.balance < token.upgradeCost) {
        alert(`You're wallet balance is ${this.state.convertFromMutez(this.state.balance)}. You need more tezzies to upgrade!`)
        return
      }
      this.upgrading = true
      this.state.loading = true
      try{
        const opts = {}
        opts.amount = token.upgradeCost
        opts.mutez = true
        // opts.storageLimit = this.state.storageLimit
        let op = await this.state.ppMain.methods.upgrade(token.tokenId).send(opts)
        await op.confirmation(1)
        this.state.loading = false
        this.upgrading = false

        token.balance -= token.upgradeReq

        const usrName = this.state.userAlias ? this.state.userAlias : this.state.formattedAddr(this.state.userAddress)
        const action = `${usrName} burned ${token.upgradeReq} ${token.rarity} ${token.metadata.name}s and minted 1 ${token.upgradeTo}. :rocket:`
        await axios({
          method: 'post',
          url:'/api/sendActivity', 
          data: {
            content: action,
          },
        })

        const upgradedTokenId = token.tokenId + 1000
        const upgradedToken = find(this.state.allTokens, (tok) => {
          return tok.tokenId === upgradedTokenId
        })
        let idx = findIndex(this.state.myTokens, mt => mt.tokenId === upgradedToken.tokenId)
        if (idx > -1) {
          this.state.myTokens[idx].balance += 1
        } else {
          this.state.myTokens.push({ ...upgradedToken, balance: 1, upgrading: false })
        }

        if (token.balance === 0) {
          idx = findIndex(this.state.myTokens, mt => { return mt.tokenId === token.tokenId})
          this.state.myTokens.splice(idx, 1)
        }

        this.state.sortTokens(this.state.sortBy, true)
        setTimeout(()=>{this.state.loadMyTokens(true, true)}, 15000)

        this.$router.push(`/potus/${upgradedTokenId}`)
        this.$forceUpdate()
      } catch(e) {
        this.upgrading = false
        this.state.loading = false
        console.error('Unable to upgrade token', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push("/")
        }
      }
    },
    async upgradeBatch (token) {
      if(this.state.balance < token.upgradeCost) {
        alert(`You're wallet balance is ${this.state.convertFromMutez(this.state.balance)}. You need more tezzies to upgrade!`)
        return
      }
      this.upgrading = true
      this.state.loading = true
      try{
        const opts = {}
        opts.amount = token.upgradeCost
        opts.mutez = true
        // opts.storageLimit = this.state.storageLimit

        const transactions = []
        const total = (token.balance - (token.balance % token.upgradeReq)) / token.upgradeReq
        this.state.log('total', total)
        for (let i = 0; i < total; i++) {
          transactions.push({
            kind: OpKind.TRANSACTION,
            ...this.state.ppMain.methods.upgrade(token.tokenId).toTransferParams(),
            ...opts,
          })
        }

        const batch = await this.state.tezos.wallet.batch(transactions)
        let op = await batch.send()
        await op.confirmation(1)
        this.state.loading = false
        this.upgrading = false
        token.balance -= (total * token.upgradeReq)

        const usrName = this.state.userAlias ? this.state.userAlias : this.state.formattedAddr(this.state.userAddress)
        const action = `${usrName} burned ${total * token.upgradeReq} ${token.rarity} ${token.metadata.name}s and minted ${total} ${token.upgradeTo}s. :rocket:`
        await axios({
          method: 'post',
          url:'/api/sendActivity', 
          data: {
            content: action,
          },
        })

        const upgradedTokenId = token.tokenId + 1000
        const upgradedToken = find(this.state.allTokens, (tok) => {
          return tok.tokenId === upgradedTokenId
        })
        let idx = findIndex(this.state.myTokens, mt => mt.tokenId === upgradedToken.tokenId)
        if (idx > -1) {
          this.state.myTokens[idx].balance += 1
        } else {
          this.state.myTokens.push({ ...upgradedToken, balance: 1, upgrading: false })
        }

        if (token.balance === 0) {
          idx = findIndex(this.state.myTokens, mt => { return mt.tokenId === token.tokenId})
          this.state.myTokens.splice(idx, 1)
        }

        this.state.sortTokens(this.state.sortBy, true)
        setTimeout(()=>{this.state.loadMyTokens(true, true)}, 15000)

        this.$router.push(`/potus/${upgradedTokenId}`)
        this.$forceUpdate()
      } catch(e) {
        this.upgrading = false
        this.state.loading = false
        console.error('Unable to upgrade token', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push("/")
        }
      }
    },
    
  },
}
</script>
