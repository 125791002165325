<template>
  <div class="grid grid-cols-1 md:grid-cols-1 gap-0 items-center justify-between text-left mt-12 overflow-hidden">
    <h1 class="text-2xl text-center">
      <AddrIcon
        :address="owner"
        :alias="alias"
      />'s Collection
    </h1>

    <div
      v-if="loading || !tokensLoaded"
      class="text-center"
    >
      Loading...
    </div>
    <div
      v-if="tokensLoaded && tokens.length"
    >
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
        <div
          v-for="(tok, tkey) in tokens.slice(offset, offset+limit)"
          :key="tkey"
          class="text-left cursor-pointer"
          @click="$router.push('/potus/'+tok.tokenId)"
        >
          <div class="flex-shrink-0 w-50 pt-3">
            <img
              class="w-full squared-full"
              :class="{'vibrate-1': tok.upgrading}"
              :src="tok.metadata.displayUri"
              alt=""
            >
            <div class="-mt-6 text-right mr-1">
              <span class="bg-white rounded p-1 opacity-50">x{{ tok.balance }} {{ tok.tokenId }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="pages > 0"
        class="flex p-10"
      >
        <div class="m-auto">
          <button
            @click="prevPage()"
          >
            <fa 
              icon="chevron-circle-left" 
              class="text-blue-link hover:text-blue-hov h-5 w-5 mr-1"
            />
          </button><span class="">Page {{ (offset/limit) + 1 }} of {{ pages }} </span><button
            @click="nextPage()"
          >
            <fa 
              icon="chevron-circle-right" 
              class="text-blue-link hover:text-blue-hov h-5 w-5 ml-1"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import { find } from 'lodash'

import AddrIcon from '../components/AddrIcon'

export default {
  name: 'ViewCollection',
  components: {
    AddrIcon,
  },
  data () {
    return {
      state: this.$root.$data.state,
      loading: false,
      tokens: [],
      tokensLoaded: false,
      sortBy: 'tokenId',
      sortDir: true,
      offset: 0,
      limit: 20,
      owner: '',
      alias: '',
    }
  },
  computed: {
    pages () {
      if (!this.tokens) {
        return 1
      }
      let tot = parseInt((this.tokens.length / this.limit).toFixed(0))
      if (tot < (this.tokens.length / this.limit)) { tot++ }
      return tot
    },
  },
  async mounted() {
    this.state.log('mounted ViewCollection', this.state.tokens, this.state.tokensLoaded)
    this.getId()
  },
  methods: {
    nextPage() {
      this.offset += this.limit
      if (this.offset >= this.tokens.length) {this.offset -= this.limit}
      
      this.state.log(this.offset, this.limit, this.tokens.slice(this.offset, this.offset+this.limit))
    },
    prevPage() {
      let offset = this.offset - this.limit
      if (offset < 0) {this.offset = 0}
      else {this.offset = offset}

      this.state.log(this.offset, this.limit, this.tokens.slice(this.offset, this.offset+this.limit))
    },
    async getId () {
      try{
        if (this.$route.params.owner) {
          this.owner = this.$route.params.owner
          const alias = find(this.state.aliases, (a) => a.address === this.owner)
          if (alias) this.alias = alias.alias
          this.loading = true
          const tokResp = await axios({
            method: 'post',
            url:'/api/myTokens', 
            data: {
              allTokens: this.state.allTokens,
              userAddress: this.$route.params.owner,
            },
          })
          
          tokResp.data.tokens.forEach((tok) => {
            this.tokens.push({ ...tok, upgrading: false })
          })

          this.sortTokens('rarity', false)
          this.tokensLoaded = true
          this.loading = false
        } else {
          this.tokens.length = 0
        }
      } catch (error) {
        console.error(error)
        this.loading = false
      }
    },
    sortTokens (sortBy, dir) {
      this.sortDir = dir
      this.sortBy = sortBy
      
      this.tokens.sort((a, b) => {
        switch (sortBy) {
          case 'name':
            if (this.sortDir)
              return (''+a.metadata.name).localeCompare(b.metadata.name)
            return (''+b.metadata.name).localeCompare(a.metadata.name)
          case 'rarity':
            if (this.sortDir)
              return a.tokenId - b.tokenId
            return b.tokenId - a.tokenId
          case 'balance':
            if (this.sortDir)
              return a.balance - b.balance
            return b.balance - a.balance
          default:
            if (this.sortDir)
              return (a.tokenId % 1000) - (b.tokenId % 1000)
            return (b.tokenId % 1000) - (a.tokenId % 1000)
        }
      })
      
    },
  },
}
</script>
