<template>
  <div>
    <div class="flex">
      <button
        class="flex-shrink ml-2 z-10"
        @click="toggleOpen"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-menu"
        ><line
          x1="3"
          y1="12"
          x2="21"
          y2="12"
        /><line
          x1="3"
          y1="6"
          x2="21"
          y2="6"
        /><line
          x1="3"
          y1="18"
          x2="21"
          y2="18"
        /></svg>
      </button>
      <p 
        v-if="state.userAddress"
        class="text-xs flex-grow mb-0 -ml-12"
      >
        <AddrIcon
          :address="state.userAddress"
          :alias="state.userAlias"
          :show-disconnect="true"
          :is-pill="true"
        />
      </p>
    </div>
    <transition :duration="200">
      <section
        v-show="isOpen"
        class="fixed inset-0 overflow-hidden z-50"
        aria-labelledby="slide-over-title"
        role="dialog"
        aria-modal="true"
      >
        <div class="absolute inset-0 overflow-hidden">
          <transition
            enter-active-class="ease-in-out duration-500"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="ease-in-out duration-500"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div
              v-show="isOpen"
              class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
              @click="toggleOpen"
            />
          </transition>

          <div class="absolute inset-y-0 right-0 pl-10 max-w-full flex">
            <transition
              enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from-class="translate-x-full"
              enter-to-class="translate-x-0"
              leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from-class="translate-x-0"
              leave-to-class="translate-x-full"
            >
              <div
                v-show="isOpen"
                class="relative w-screen max-w-md"
              >
                <transition
                  enter-active-class="ease-in-out duration-500"
                  enter-from-class="opacity-0"
                  enter-to-class="opacity-100"
                  leave-active-class="ease-in-out duration-500"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <div
                    v-show="isOpen"
                    class="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4"
                  >
                    <button
                      class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      @click="toggleOpen"
                    >
                      <span class="sr-only">Close panel</span>
                      <svg
                        class="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </transition>
                <div class="h-full flex flex-col justify-between py-6 bg-white shadow-xl overflow-y-scroll text-left pl-6">
                  <div class="flex flex-col ">
                    <h2
                      id="menu-title"
                      class="text-lg font-medium text-gray-900 mb-8"
                    >
                      Menu
                    </h2>
                    
                    <router-link
                      v-for="(link, i) in links"
                      :key="i"
                      class="text-base font-medium text-gray-500 hover:text-gray-900 mb-2"
                      :to="link.path"
                    >
                      <span @click="toggleOpen">{{ link.label }}</span>
                    </router-link>
                  </div>

                  <p 
                    v-if="state.userAddress"
                    class="text-sm"
                  >
                    Connected to:<br>
                    <AddrIcon
                      :address="state.userAddress"
                      :alias="state.userAlias"
                      :show-disconnect="true"
                      :is-pill="true"
                    /> <br><br><span class="">({{ state.convertFromMutez(state.balance) }}ꜩ)</span>
                  </p>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import AddrIcon from './AddrIcon.vue'
import links from './nav-links'
export default {
  name: 'BurgerMenu',
  components: {
    AddrIcon,
  },
  props: {
    isOpen: Boolean,
    tradingFee: Number,
    toggleOpen: Function,
  },
  computed: {
    state () {
      return this.$root.$data.state
    },
    links () {
      return links(this.state)
    },
  },
  methods: {
    async disconnect () {
      try{
        await this.state.disconnect()
        this.$router.push("/")
      } catch(e) {
        console.error('Unable to disconnect wallet', e)
      }
    },
  },
}
</script>
