<template>
  <div class="flex p-10">
    <div class="m-auto">
      <button
        @click="$emit('firstPage')"
      >
        <fa 
          icon="angle-double-left" 
          class="text-blue-link hover:text-blue-hov h-5 w-5 mr-3"
        />
      </button>
      <button
        @click="$emit('prevPage')"
      >
        <fa 
          icon="chevron-circle-left" 
          class="text-blue-link hover:text-blue-hov h-5 w-5 mr-2"
        />
      </button><span class="">{{ (offset/(limit)) + 1 }} of {{ pages }} </span><button
        @click="$emit('nextPage')"
      >
        <fa 
          icon="chevron-circle-right" 
          class="text-blue-link hover:text-blue-hov h-5 w-5 ml-2"
        />
      </button>
      <button
        @click="$emit('lastPage')"
      >
        <fa 
          icon="angle-double-right" 
          class="text-blue-link hover:text-blue-hov h-5 w-5 ml-3"
        />
      </button>
      <br>
      <div class="grid grid-flow-cols">
        <div class="text-xs m-auto">
          <select
            v-model="perPage"
            class="p-1 border-2 rounded"
            @change="$emit('pageSizeSelected', $event.target.value)"
          >
            <slot name="perPageOptions" />
          </select> per page
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      offset: {
        type: Number,
        default: 0,
      },
      // eslint-disable-next-line vue/require-default-prop
      limit: Number,
      pages: {
        type: Number,
        default: 1,
      },
    },
    emits: [
      'firstPage',
      'prevPage',
      'nextPage',
      'lastPage',
      'pageSizeSelected',
    ],
    data() {
      return {
        perPage: this.limit,
      }
    },
    watch: {
      limit () {
        this.perPage = this.limit
      },
    },
  }
</script>