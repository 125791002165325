<template>
  <div class="grid grid-cols-6 gap-2 items-center justify-between">
    <div>
      <router-link to="/">
        <img 
          src="../assets/eagle-right.png" 
          :class="{'animate-pulse': state.loading}"
          width="100"
        >
      </router-link>
    </div>
    <div class="col-span-4 flex flex-col items-center">
      <h1 class="text-2xl md:text-4xl lg:text-5xl my-0">
        <router-link
          to="/"
          class="text-black hover:text-black"
        >
          PiXEL POTUS
        </router-link>
      </h1>
      <p
        v-if="!state.userAddress"
        class="text-xs hidden md:inline-block"
      >
        🇺🇸🇺🇸🇺🇸🇺🇸<a 
          href="#"
          class="text-blue-500 hover:text-blue-600 cursor-pointer"
          @click="connectWallet"
        >
          Connect Wallet
        </a>🇺🇸🇺🇸🇺🇸🇺🇸
      </p>
      <p 
        v-if="state.userAddress"
        class="text-xs hidden md:inline-block breakwords"
      >
        Connected to <AddrIcon
          :address="state.userAddress"
          :alias="state.userAlias"
          :show-disconnect="true"
          :is-pill="true"
        /> ({{ state.convertFromMutez(state.balance) }}ꜩ)
      </p>
    </div>
    <div>
      <router-link to="/">
        <img 
          class="float-right"
          :class="{'animate-pulse': state.loading}"
          src="../assets/eagle.png" 
          width="100"
        >
      </router-link>
    </div>
  </div>
</template>

<script>
import AddrIcon from './AddrIcon.vue'


export default {
  name: 'Crest',
  components: {
    AddrIcon,
  },
  props: {
    state: {
      type: Object,
      default: () => { return {}},
    },
    connectWallet: { 
      type: Function,
      default: () => { return {}},
    },
  },
  methods: {
    async disconnect () {
      try{
        await this.state.disconnect()
        this.$router.push("/")
      } catch(e) {
        console.error('Unable to disconnect wallet', e)
      }
    },
  },
}
</script>

<style>

</style>