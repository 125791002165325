<template>
  <div class="grid grid-cols-1 md:grid-cols-1 gap-0 items-center justify-between text-left mt-12 overflow-hidden">
    <h1 class="text-2xl text-center">
      My Collection<button
        class="text-blue-link hover:text-blue-hov"
        @click="()=>{
          state.history.isOpen = true
        }"
      >
        <fa 
          icon="history" 
          class="h-5 w-5 align-baseline"
        />
      </button>
      <br>
      <span class="text-xs">Last refresh: <br>{{ myTokensTimestamp ? myTokensTimestamp.fromNow() : 'never' }} <button
        v-if="myTokensTimestamp"
        class="text-blue-link hover:text-blue-hov"
        alt="Refresh Tokens"
        :disabled="state.myTokensLoading"
        @click="state.loadMyTokens(true, true)"
      ><fa 
        icon="sync" 
        :class="{'animate-spin':state.myTokensLoading }"
        class="h-5 w-5 align-bottom"
      /></button></span>
    </h1>

    <div
      v-if="state.myStats"
      class="nes-container is-rounded text-center"
    >
      Sales: {{ state.convertFromMutez(state.myStats.sales) }}ꜩ,
      Spent: {{ state.convertFromMutez(state.myStats.spent) }}ꜩ,
      Tokens: {{ state.myStats.total }}
    </div>
    <div
      v-if="state.loading || !state.myTokensLoaded"
      class="text-center"
    >
      Loading...
    </div>


    <div
      v-if="state.myTokensLoaded"
      class="flex flex-col border-t border-b border-dashed"
    >
      <div
        class="p-5 nes-container is-rounded"
      >
        <label
          for="filter_trades"
          class="block text-sm font-medium text-gray-700"
        >Filters</label>
        <input
          id="filter_trades"
          v-model="filter"
          type="text"
          name="filter_trades"
          class="w-full p-2 border-2 mt-1 focus:ring-blue-500 focus:border-blue-500 block shadow-sm text-xs sm:text-sm border-gray-300 rounded-none w-full md:w-3/5 xl:w-1/3 "
          placeholder="potus name"
          @input="updateFilters()"
        >
        <hr>
        <label
          class="text-sm font-medium text-gray-700"
        >Rarities:</label>
        <div class="relative inline-block my-2 mr-2 align-middle select-none transition duration-200 ease-in">
          <VueMultiselect
            v-model="rarities"
            :options="raritiesOptions"
            :multiple="true"
            :show-labels="false"
            placeholder="Select Rarities"
            @select="updateFilters"
            @remove="updateFilters"
          />
        </div>
      </div>
      <div
        v-if="visibleTokens.length"
        class="hidden lg:inline -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"
      >
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead
                v-if="!upgrading"
                class="bg-gray-50"
              >
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    <a
                      href="#"
                      @click="state.sortTokens('name')"
                    >Name</a>
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    <a
                      href="#"
                      @click="state.sortTokens('balance')"
                    >Balance</a>
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    <a
                      href="#"
                      @click="state.sortTokens('rarity')"
                    >Rarity</a>
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                  >
                    Upgrade Reqs
                  </th>
                  <th
                    scope="col col-2 gap-2"
                    class="relative px-6 py-3"
                  >
                    <button
                      v-if="!batchSelect"
                      class="nes-btn is-primary text-xs"
                      @click="batchSelect = true"
                    >
                      Start Batch Upgrade
                    </button>
                    <button
                      v-if="batchSelect && !upgrading"
                      class="nes-btn is-primary text-xs"
                      @click="upgradeBatch(batchTokens)"
                    >
                      Upgrade {{ batchTokens.length }}
                    </button>
                    <button
                      v-if="batchSelect && !upgrading"
                      class="nes-btn is-normal text-xs"
                      @click="batchSelect = false; batchTokens.length = 0"
                    >
                      Cancel
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  v-for="(tok, tkey) in visibleTokens.slice(offset, offset+limit)"
                  v-show="(!upgrading || tok.upgrading) && (!batchSelect || (batchSelect && tok.balance >= tok.upgradeReq))"
                  :key="tkey"
                >
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div
                        class="flex-shrink-0"
                        :class="{'w-full':tok.upgrading, 'w-10' : !tok.upgrading}"
                      >
                        <img
                          class="squared-full"
                          :class="{'vibrate-1 w-full': tok.upgrading, 'w-10' : !tok.upgrading}"
                          :src="tok.metadata.displayUri"
                          alt=""
                        >
                      </div>
                      <div
                        v-if="!tok.upgrading"
                        class="ml-4"
                      >
                        <div class="text-sm font-medium text-gray-900">
                          <router-link
                            class="text-base font-medium"
                            :to="`/potus/${tok.tokenId}`"
                          >
                            {{ tok.metadata.name }}
                          </router-link>
                        </div>
                        <div class="text-sm text-gray-500">
                          Token ID: {{ tok.tokenId }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    v-if="!tok.upgrading"
                    class="px-6 py-4 whitespace-nowrap"
                  >
                    <span class="px-2 inline-flex text-xs leading-5 font-semibold squared-full bg-green-100 text-green-800">
                      {{ tok.balance }}
                    </span>
                  </td>
                  <td
                    v-if="!tok.upgrading"
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                  >
                    {{ tok.rarity }}
                  </td>
                  <td
                    v-if="!tok.upgrading"
                    class="px-6 py-4 whitespace-nowrap"
                  >
                    <div
                      v-if="tok.rarity === 'unique'"
                      class="text-sm"
                    >
                      Maxed
                    </div>
                    <span v-else>
                      <div class="text-sm text-gray-900">
                        <img
                          width="10"
                          class="inline mr-2 mb-2"
                          src="../assets/flame.png"
                        >{{ tok.upgradeReq }} {{ tok.rarity }}s
                      </div>
                      <div class="text-sm squared-full text-green-800">
                        <fa 
                          icon="coins" 
                          class="h-5 w-5 mr-1"
                        />{{ state.convertFromMutez(tok.upgradeCost) }}<span class="text-gray-500">tez</span>
                      </div>
                      <span class="text-xs text-gray-500">=1x {{ tok.upgradeTo }}</span>
                    </span>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <div v-if="tok.rarity !== 'unique' && !batchSelect">
                      <button
                        :disabled="tok.balance < tok.upgradeReq || tok.upgrading"
                        class="text-blue-link hover:text-blue-hov block"
                        @click="upgrade(tok)"
                      >
                        <span v-if="tok.upgrading">
                          <fa 
                            icon="cog" 
                            class="animate-spin h-5 w-5 mr-3"
                          />
                          Upgrading... Pls wait up to 1 min for block confirmation.
                        </span>
                        <span v-else>
                          <span
                            v-if="tok.balance < tok.upgradeReq || state.loading"
                            class="line-through text-gray-500 hover:text-gray-500"
                          >
                            Upgrade Once
                          </span>
                          <span v-else>
                            Upgrade Once
                          </span>
                        </span>
                      </button>
                      <button
                        v-if="!tok.upgrading && parseInt(tok.balance / tok.upgradeReq) > 1"
                        :disabled="tok.balance < tok.upgradeReq || tok.upgrading"
                        class="text-blue-link hover:text-blue-hov block"
                        @click="upgradeBatch(tok)"
                      >
                        <hr>
                        <span>
                          <span
                            v-if="tok.balance < tok.upgradeReq || state.loading"
                            class="line-through text-gray-500 hover:text-gray-500"
                          >
                            Upgrade All
                          </span>
                          <span v-else>
                            Upgrade All
                          </span>
                        </span>
                      </button>
                    </div>
                    <div v-if="batchSelect && tok.balance >= tok.upgradeReq">
                      <span v-if="tok.upgrading">
                        <fa 
                          icon="cog" 
                          class="animate-spin h-5 w-5 mr-3"
                        />
                        Upgrading... Pls wait up to 1 min for block confirmation.
                      </span>
                      <div
                        v-if="!tok.upgrading"
                        class="relative inline-block w-10 mt-2 mr-2 align-middle select-none transition duration-200 ease-in"
                      >
                        <input
                          type="checkbox"
                          class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                          @input="(e) => selectForBatch(e, tok)"
                        >
                        <label
                          class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                        /> 
                      </div>
                      <label
                        v-if="!tok.upgrading"
                        class="text-xs text-gray-700 inline"
                      >Upgrade?</label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="pages > 0 && !upgrading"
              class="flex p-10"
            >
              <div class="m-auto">
                <button
                  @click="prevPage()"
                >
                  <fa 
                    icon="chevron-circle-left" 
                    class="text-blue-link hover:text-blue-hov h-5 w-5 mr-1"
                  />
                </button><span class="">Page {{ (offset/limit) + 1 }} of {{ pages }} </span><button
                  @click="nextPage()"
                >
                  <fa 
                    icon="chevron-circle-right" 
                    class="text-blue-link hover:text-blue-hov h-5 w-5 ml-1"
                  />
                </button>
              </div>
            </div>
            <SpeechBubble
              :img="state.images.pierce"
              :show="upgrading"
              :potus="'Pres. Pierce'"
              :is-left="false"
            >
              Enjoy this random POTUS gif while u wait. You can also go do other stuff without affecting the transaction. <br>
              <img
                :src="nextGif"
                width="300"
                class="m-auto"
              >
            </SpeechBubble>
          </div>
        </div>
      </div>
      <div
        v-if="visibleTokens.length"
        class="lg:hidden divide-y divide-light-blue-400"
      >
        <div
          v-for="(tok, tkey) in visibleTokens.slice(offset, offset+limit)"
          :key="tkey"
          class="block w-full h-20 text-left flex cursor-pointer"
          @click="$router.push('/potus/'+tok.tokenId)"
        >
          <div class="flex-shrink-0 w-10 pt-3">
            <img
              class="w-10 squared-full"
              :class="{'vibrate-1': tok.upgrading}"
              :src="tok.metadata.displayUri"
              alt=""
            >
          </div>
          <div class="text-sm flex-grow p-1 pt-2">
            <span>{{ tok.metadata.name }}</span>
            <br><span class="text-xs text-gray-500">{{ tok.balance }}x {{ tok.rarity }}</span>
            <br><span class="text-xs text-gray-500">Token ID: {{ tok.tokenId }}</span>
          </div>
          <div class="flex-none p-5 pt-8">
            <router-link :to="'/potus/'+tok.tokenId">
              <fa 
                icon="chevron-right" 
                class="text-blue-link hover:text-blue-hov h-5 w-5 ml-1"
              />
            </router-link>
          </div>
        </div>
        <div
          v-if="pages > 0"
          class="flex p-10"
        >
          <div class="m-auto">
            <button
              @click="prevPage()"
            >
              <fa 
                icon="chevron-circle-left" 
                class="text-blue-link hover:text-blue-hov h-5 w-5 mr-1"
              />
            </button><span class="">Page {{ (offset/limit) + 1 }} of {{ pages }} </span><button
              @click="nextPage()"
            >
              <fa 
                icon="chevron-circle-right" 
                class="text-blue-link hover:text-blue-hov h-5 w-5 ml-1"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <SpeechBubble
      :img="state.images.jackson"
      :show="showHelp && state.myTokensLoaded"
      :potus="'Pres. Jackson'"
      class="mt-6"
    >
      The most precious resource on earth.. pixelated POTUSes. 
      <span v-if="myTokens.length">
        Gaze upon your trove in wonder. Also... you can burn multiple copies of like tokens in order to upgrade to higher rarities. <span class="hidden lg:inline">The upgrade requirements for each level are listed in the "Upgrade Reqs" column.</span> <span class="lg:hidden">Tap a POTUS to see in depth stats and upgrade options.</span> Get it? <br><a
          href="#"
          class="text-blue-500 hover:text-blue-600"
          @click="toggleHelp"
        >Got it</a>
      </span>
      <span v-else-if="state.myTokensLoaded">
        And you seem to have a deficit! You should go <router-link
          to="/claim"
        >
          claim
        </router-link> some free POTUSes or trade for some from the <router-link
          to="/market"
        >
          market.
        </router-link>
      </span>
    </SpeechBubble>
    <img
      v-if="myTokens.length"
      class="w-full"
      src="@/assets/level-up.png"
    >
  </div>
</template>

<script>

import { OpKind } from '@taquito/taquito'
import axios from 'axios'
import { debounce,find, findIndex } from 'lodash'
import VueMultiselect from 'vue-multiselect'

import SpeechBubble from '../components/SpeechBubble'

export default {
  name: 'MyCollection',
  components: {
    SpeechBubble,
    VueMultiselect,
  },
  data () {
    return {
      state: this.$root.$data.state,
      myTokens: this.$root.$data.state.myTokens,
      visibleTokens: [],
      offset: 0,
      limit: 20,
      confirmation: false,
      showHelp: true,
      upgrading: false,
      nextGif: null,
      batchSelect: false,
      batchTokens: [],
      rarities: [],
      raritiesOptions: ['common','uncommon','rare','epic','legendary','unique'],
      filter: '',
    }
  },
  computed: {
    pages () {
      if (!this.myTokens) {
        return 1
      }
      let tot = parseInt((this.visibleTokens.length / this.limit).toFixed(0))
      if (tot < (this.visibleTokens.length / this.limit)) { tot++ }
      return tot
    },
    myTokensTimestamp () {
      return this.state.myTokensTimestamp
    },
  },
  async mounted() {
    if (this.state.userAddress === null) {
      this.$router.push("/")
    }
    this.state.log('mounted MyCollection', this.state.myTokens, this.state.myTokensLoaded)
    const sh = localStorage.getItem('showCollectionHelp')
    this.showHelp = sh ? JSON.parse(sh) : true
    this.updateFilters()

    // this.getMyStats()
  },
  methods: {
    updateFilters() {
      this.debouncedUpdate()
    },
    debouncedUpdate: debounce(function () {
      this.offset = 0
      this.visibleTokens = this.myTokens.filter(t => {
        let sRet = true
        if (this.filter !== '' && t.metadata.name.toLowerCase().indexOf(this.filter.toLowerCase()) == -1) {
          sRet = false
        }
        if (this.rarities.length && !this.rarities.includes(t.rarity)) {
          sRet = false
        }
        return sRet
      })
    }, 50),
    selectForBatch(e, tok) {
      if (e.target.checked) {
        this.batchTokens.push(tok)
      } else {
        const idx = findIndex(this.batchTokens, (t) => t.tokenId == tok.tokenId)
        this.batchTokens.splice(idx, 1)
      }
    },
    nextPage() {
      this.offset += this.limit
      if (this.offset >= this.myTokens.length) {this.offset -= this.limit}
    },
    prevPage() {
      let offset = this.offset - this.limit
      if (offset < 0) {this.offset = 0}
      else {this.offset = offset}
    },
    toggleHelp() {
      this.showHelp = false
      localStorage.setItem('showCollectionHelp', JSON.stringify(false))
    },
    async upgrade (token) {
      if(this.state.balance < token.upgradeCost) {
        alert(`You're wallet balance is ${this.state.convertFromMutez(this.state.balance)}. You need more tezzies to upgrade!`)
        return
      }
      this.nextGif = this.state.gifs[Math.floor(Math.random() * this.state.gifs.length)]
      this.upgrading = true
      token.upgrading = true
      this.state.loading = true
      try{
        const opts = {}
        opts.amount = token.upgradeCost
        opts.mutez = true
        // opts.storageLimit = this.state.storageLimit
        let op = await this.state.ppMain.methods.upgrade(token.tokenId).send(opts)
        await op.confirmation(1)
        this.state.loading = false
        // token.upgrading = false
        // this.upgrading = false
        token.balance -= token.upgradeReq
        this.confirmation = true
        setTimeout(()=>{this.confirmation = false}, 30000)

        const usrName = this.state.userAlias ? this.state.userAlias : this.state.formattedAddr(this.state.userAddress)
        const action = `${usrName} burned ${token.upgradeReq} ${token.rarity} ${token.metadata.name}s and minted 1 ${token.upgradeTo}. :rocket:`
        await axios({
          method: 'post',
          url:'/api/sendActivity', 
          data: {
            content: action,
          },
        })

        const upgradedTokenId = token.tokenId + 1000
        const upgradedToken = find(this.state.allTokens, (tok) => {
          return tok.tokenId === upgradedTokenId
        })
        let idx = findIndex(this.myTokens, mt => mt.tokenId === upgradedToken.tokenId)
        if (idx > -1) {
          this.myTokens[idx].balance += 1
        } else {
          this.myTokens.push({ ...upgradedToken, balance: 1, upgrading: false })
        }

        if (token.balance === 0) {
          idx = findIndex(this.myTokens, mt => { return mt.tokenId === token.tokenId})
          this.myTokens.splice(idx, 1)
        }

        this.state.sortTokens(this.state.sortBy, true)
        this.$forceUpdate()
        setTimeout(()=>{this.state.loadMyTokens(true, true)}, 15000)

        token.upgrading = false
        this.upgrading = false

        this.$router.push(`/potus/${upgradedTokenId}`)
      } catch(e) {
        token.upgrading = false
        this.upgrading = false
        this.state.loading = false
        console.error('Unable to upgrade token', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push("/")
        }
      }
    },
    async upgradeBatch (tokens) {
      if (!Array.isArray(tokens)) {
        tokens = [tokens]
      }
      this.nextGif = this.state.gifs[Math.floor(Math.random() * this.state.gifs.length)]
      this.upgrading = true
      this.state.loading = true
      try{
        // opts.storageLimit = this.state.storageLimit
        const transactions = []
        for (let token of tokens) {
          const opts = {}
          opts.amount = token.upgradeCost
          opts.mutez = true
          token.upgrading = true
          let total = (token.balance - (token.balance % token.upgradeReq)) / token.upgradeReq
          if (total > 10) {
            total = 10
          }
          this.state.log('total', total)
          for (let i = 0; i < total; i++) {
            transactions.push({
              kind: OpKind.TRANSACTION,
              ...this.state.ppMain.methods.upgrade(token.tokenId).toTransferParams(),
              ...opts,
            })
          }
        }

        const batch = await this.state.tezos.wallet.batch(transactions)
        let op = await batch.send()
        await op.confirmation(1)
        
        // this.confirmation = true
        // setTimeout(()=>{this.confirmation = false}, 30000)

        for (let token of tokens) {
          let total = (token.balance - (token.balance % token.upgradeReq)) / token.upgradeReq
          token.balance -= (total * token.upgradeReq)

          const usrName = this.state.userAlias ? this.state.userAlias : this.state.formattedAddr(this.state.userAddress)
          const action = `${usrName} burned ${total * token.upgradeReq} ${token.rarity} ${token.metadata.name}s and minted ${total} ${token.upgradeTo}s. :rocket:`
          await axios({
            method: 'post',
            url:'/api/sendActivity', 
            data: {
              content: action,
            },
          })

          const upgradedTokenId = token.tokenId + 1000
          const upgradedToken = find(this.state.allTokens, (tok) => {
            return tok.tokenId === upgradedTokenId
          })
          let idx = findIndex(this.myTokens, mt => mt.tokenId === upgradedToken.tokenId)
          if (idx > -1) {
            this.myTokens[idx].balance += total
          } else {
            this.myTokens.push({ ...upgradedToken, balance: total, upgrading: false })
          }
          if (token.balance === 0) {
            idx = findIndex(this.myTokens, mt => { return mt.tokenId === token.tokenId})
            this.myTokens.splice(idx, 1)
          }

          // this.state.sortTokens(this.state.sortBy, true)
          // this.$forceUpdate()

          token.upgrading = false
          if (tokens.length == 1) {
            setTimeout(()=>{this.state.loadMyTokens(true, true)}, 15000)
            this.upgrading = false
            this.$router.push(`/potus/${upgradedTokenId}`)
          }
        }
        this.batchSelect = false
        this.batchTokens.length = 0
        setTimeout(()=>{
          this.state.loadMyTokens(true, true)
          this.upgrading = false
          this.state.loading = false
        }, 15000)
        
      } catch(e) {
        for (let token of tokens) {
          token.upgrading = false
        }
        this.batchSelect = false
        this.batchTokens.length = 0
        this.upgrading = false
        this.state.loading = false
        console.error('Unable to upgrade tokens', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push("/")
        }
      }
    },
    async getMyStats () {
      const resp = await axios({
        url:'/api/myStats', 
        params: {
          owner: this.state.userAddress,
        },
      })
      this.state.myStats = resp.data.myStats
    },    
  },
}
</script>
