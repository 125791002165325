<template>
  <div class="grid grid-cols-1 gap-12 items-center justify-between text-left mt-12 overflow-hidden pb-32">
    <h1 class="text-2xl text-center">
      Leading Constituents
    </h1>

    <SpeechBubble
      :img="state.images.wilson"
      :show="showHelp"
      :potus="'Pres. Wilson'"
    >
      “You are not here merely to make a living. You are here in order to enable the world to live more amply, with greater vision, with a finer spirit of hope and achievement. You are here to enrich the world, and you impoverish yourself if you forget the errand.” ― Woodrow Wilson <a
        href="#"
        class="text-blue-500 hover:text-blue-600"
        @click="toggleHelp"
      >Cool</a>
    </SpeechBubble>

    <div
      v-if="loading"
      class="text-center"
    >
      Loading...
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 text-center">
      <a
        href="#"
        class="text-blue-500 hover:text-blue-600"
        :class="{'underline text-gray-900': view=='total'}"
        @click="changeView('total')"
      >Biggest</a>
      <a
        href="#"
        class="text-blue-500 hover:text-blue-600"
        :class="{'underline text-gray-900': view=='weighted'}"
        @click="changeView('weighted')"
      >Most Rare</a>
      <a
        href="#"
        class="text-blue-500 hover:text-blue-600"
        :class="{'underline text-gray-900': view=='barters'}"
        @click="changeView('barters')"
      >Top Barterers</a>
      <a
        href="#"
        class="text-blue-500 hover:text-blue-600"
        :class="{'underline text-gray-900': view=='sales'}"
        @click="changeView('sales')"
      >Top Capitalists</a>
    </div>

    <div
      v-if="myRank !== -1"
      class="rounded-full py-2 px-3 bg-black text-white m-auto"
    >
      Your Rank: #{{ myRank }},
      Alias: {{ state.userAlias || 'none' }} <button
        class="text-blue-link hover:text-blue-hov"
        @click="()=>{
          showAliasInput = !showAliasInput
          aliasInput = state.userAlias
        }"
      >
        <fa 
          icon="edit" 
          class="h-5 w-5 align-baseline"
        />
      </button>
    </div>
    <div
      v-if="showAliasInput"
      class="m-auto"
    >
      <div class="nes-field grid grid-cols-3">
        <label
          for="alias_field"
          class="col-span-3 text-xs"
        >Set an alias up to 15 chars long for {{ state.convertFromMutez(aliaserCost) }}tez.<br>It will be displayed everywhere in place of<br> your address. Pls keep it PG :)</label>
        <input
          id="alias_field"
          v-model="aliasInput"
          type="text"
          class="nes-input col-span-2"
          :maxlength="aliaserMaxLen"
        >
        <button
          class="nes-btn is-primary col-span-1"
          :disabled="settingAlias"
          @click="setAlias"
        >
          <fa 
            v-if="settingAlias"
            icon="cog" 
            class="animate-spin h-5 w-5 align-baseline"
          />
          Set<span v-if="settingAlias">ting</span> Alias
        </button>
      </div>
    </div>
    
    <div
      class="flex flex-col"
    >
      <Pagination 
        :offset="offset"
        :limit="limit"
        :pages="pages"
        class="-mt-10"
        @firstPage="firstPage"
        @prevPage="prevPage"
        @nextPage="nextPage"
        @lastPage="lastPage"
        @pageSizeSelected="pageSizeSelected"
      >
        <template #perPageOptions>
          <option value="5">
            5
          </option>
          <option value="10">
            10
          </option>
          <option value="20">
            20
          </option>
          <option value="50">
            50
          </option>
          <option value="100">
            100
          </option>
        </template>
      </Pagination>
      <div class="hidden lg:inline-block -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-2 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200 text-sm lg:text-base">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Rank
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Owner
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Collection Size
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    # Trades
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    # Barters
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Sales
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  v-for="(entry, key) in leaders"
                  :key="key"
                >
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ entry.rank }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <router-link :to="'/collection/'+entry.id">
                        <AddrIcon
                          :address="entry.id"
                          :alias="entry.alias"
                        />
                      </router-link>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      {{ entry.total }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      {{ entry.trades }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      {{ entry.barters }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      {{ state.convertFromMutez(entry.sales) }}tez
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        class="grid grid-cols-1 sm:grid-cols-2 lg:hidden gap-2"
      >
        <div
          v-for="(entry, key) in leaders"
          :key="key"
          class="nes-container is-rounded"
          :class="{'is-dark': entry.id === state.userAddress }"
        >
          <div class="flex text-left text-xs items-center">
            <div class="flex-shrink mr-2 text-xl">
              #{{ entry.rank }}
            </div>
            <div class="flex-grow">
              <span class="text-base"><router-link :to="'/collection/'+entry.id"><AddrIcon
                :address="entry.id"
                :alias="entry.alias"
              /></router-link></span><br>
              <span class="text-base">{{ entry.total }}</span> POTUSes<br>
              <span class="text-base">{{ entry.trades }}</span> trades<br>
              <span class="text-base">{{ entry.barters }}</span> barters<br>
              <span class="text-base">{{ state.convertFromMutez(entry.sales) }}ꜩ</span> in sales<br>
            </div>
          </div>
        </div>
      </div>
      <Pagination 
        :offset="offset"
        :limit="limit"
        :pages="pages"
        @firstPage="firstPage"
        @prevPage="prevPage"
        @nextPage="nextPage"
        @lastPage="lastPage"
        @pageSizeSelected="pageSizeSelected"
      >
        <template #perPageOptions>
          <option value="5">
            5
          </option>
          <option value="10">
            10
          </option>
          <option value="20">
            20
          </option>
          <option value="50">
            50
          </option>
          <option value="100">
            100
          </option>
        </template>
      </Pagination>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

import AddrIcon from '../components/AddrIcon'
import Pagination from '../components/Pagination'
import SpeechBubble from '../components/SpeechBubble'

export default {
  name: 'Leaders',
  components: {
    AddrIcon,
    Pagination,
    SpeechBubble,
  },
  data () {
    return {
      state: this.$root.$data.state,
      view: 'total',
      leaders: [],
      total: 0,
      myRank: -1,
      loading: false,
      showHelp: true,
      filter: '',
      offset: 0,
      limit: 20,
      showAliasInput: false,
      aliasInput: '',
      aliaserCost: 250000,
      aliaserMaxLen: 15,
      settingAlias: false,
    }
  },
  computed: {
    pages () {
      if (!this.leaders) {
        return 1
      }
      let tot = parseInt((this.total / this.limit).toFixed(0))
      if (tot < (this.total / this.limit)) { tot++ }
      return tot
    },
  },
  async mounted() {
    const sh = localStorage.getItem('showLeadersHelp')
    this.showHelp = sh ? JSON.parse(sh) : true
    await this.getLeaders()
  },
  methods: {
    nextPage() {
      this.offset += this.limit
      if (this.offset >= this.total) {this.offset -= this.limit}
      this.getLeaders()
    },
    prevPage() {
      let offset = this.offset - this.limit
      if (offset < 0) {this.offset = 0}
      else {this.offset = offset}
      this.getLeaders()
    },
    firstPage() {
      this.offset = 0
      this.getLeaders()
    },
    lastPage() {
      this.offset = (this.pages - 1) * this.limit
      this.getLeaders()
    },
    async pageSizeSelected (value) {
      this.offset = 0
      this.limit = value
      this.getLeaders()
    },
    changeView(v) {
      this.view = v
      this.leaders = []
      this.total = 0
      this.offset = 0
      this.myRank = -1
      this.getLeaders()
    },
    async refresh() {
      await this.getLeaders()
    },
    toggleHelp() {
      this.showHelp = false
      localStorage.setItem('showLeadersHelp', JSON.stringify(false))
    },
    async getLeaders () {
      this.state.log('load leaders')
      this.loading = true
      try {
        let resp = await axios({
            url:'/api/leaders',
            params: {
              orderBy: this.view,
              limit: this.limit,
              offset: this.offset,
            },
          })
        this.leaders = resp.data.leaders
        this.total = resp.data.total

        this.loading = false

        if (this.state.userAddress && this.myRank == -1) {
          resp = await axios({
            url:'/api/myRank',
            params: {
              orderBy: this.view,
              owner: this.state.userAddress,
            },
          })
          this.myRank = resp.data.rank
        }

      } catch (error) {
        this.loading = false
      }
    },
    async setAlias () {
      try{
        if (this.state.userAddress === null) {
          return
        }
        this.aliasInput = this.aliasInput.trim()
        if (this.aliasInput.length > this.aliaserMaxLen) {
          alert('Max alias length is 15 chars. If you feel this is too restrictive, please reach out to an admin on discord.')
          return
        }
        if (this.aliasInput.length < 3) {
          alert('Min alias length is 3 chars. If you feel this is too restrictive, please reach out to an admin on discord.')
          return
        }
        this.settingAlias = true

        const aliaser = await this.state.tezos.wallet.at(process.env.VUE_APP_PP_ALIASER)

        const opts = {}
        opts.amount = this.aliaserCost
        opts.mutez = true
        let op = await aliaser.methods.set_alias(this.aliasInput).send(opts)
        await op.confirmation(1)

        this.state.userAlias = this.aliasInput
        this.settingAlias = false
        this.showAliasInput = false

        await axios({
          method: 'post',
          url:'/api/addAlias', 
          data: {
            userAddress: this.state.userAddress,
            userAlias: this.state.userAlias,
          },
        })

        await axios({
          method: 'post',
          url:'/api/sendActivity', 
          data: {
            content: `${this.state.formattedAddr(this.state.userAddress)} is now known as '${this.aliasInput}' :detective:`,
          },
        })
      } catch(e) {
        this.settingAlias = false
        console.error('Unable to connect wallet', e)
      }
    },
  },
}
</script>
