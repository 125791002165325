<template>
  <div class="flex flex-col items-center justify-between text-center mt-6">
    <h1 class="text-2xl text-center">
      <span v-if="!trading">View Auction</span>
      <span v-else><fa 
        icon="cog" 
        class="animate-spin h-5 w-5 mr-3"
      />Please wait up to 1 min for block confirmation.</span>
    </h1>
    <div
      v-if="auction === false"
      class="text-center"
    >
      This auction has concluded.
    </div>

    <div
      v-if="loading"
      class="text-center"
    >
      Loading...
    </div>
    <Auction
      v-if="auction"
      class="m-auto"
      :auction="auction"
      :bid-complete="bidComplete"
      :claim-complete="claimComplete"
    />
    <SpeechBubble
      :img="state.images.pierce"
      :show="trading"
      :potus="'Pres. Pierce'"
      :is-left="false"
    >
      Enjoy this random POTUS gif while u wait. You can also go do other stuff without affecting the transaction. <br>
      <img
        :src="nextGif"
        width="300"
        class="m-auto"
      >
    </SpeechBubble>
  </div>
</template>

<script>
import * as signalR from '@microsoft/signalr'
import axios from 'axios'
import { find, findIndex } from 'lodash'
import moment from 'moment'

import Auction from '../components/Auction'
import SpeechBubble from '../components/SpeechBubble'

export default {
  name: 'ViewAuction',
  components: {
    Auction,
    SpeechBubble,
  },
  data () {
    return {
      state: this.$root.$data.state,
      auction: null,
      auctionId: null,
      tradingFee: 25/1000,
      confirmation: false,
      loading: false,
      trading: false,
      nextGif: null,
      socket: null,
      shouldRecon: true,
    }
  },
  async mounted() {
    this.state.log('mounted viewAuction')
    this.getId()
  },
  async unmounted() {
    this.state.log('unmounted viewAuction')
    this.shouldRecon = false
  },
  methods: {
    async getId () {
      if (this.$route.params.auctionId) {
        this.auctionId = parseInt(this.$route.params.auctionId)
        this.shouldRecon = true
        await this.getAuction()
        await this.initSocket()
      } else {
        this.auction = null
      }
    },
    async initSocket() {
      if (!this.socket) {
        this.socket = new signalR.HubConnectionBuilder()
          .withUrl(`https://api${process.env.VUE_APP_TZKT}.tzkt.io/v1/events`)
          .build()
        // open connection
        await this.socket.start()
        // subscribe to auction transactions
        await this.socket.invoke('SubscribeToOperations', {
          address: process.env.PP_AUCTION,
          types: 'transaction',
        })

        // auto-reconnect
        this.socket.onclose(()=>{
          this.socket = null
          if(this.shouldRecon) {
            this.initSocket()
          }
        })

        this.socket.on('operations', async (msg) => {
          let load = false
          if (msg.type == 1) {
            await Promise.all(
              msg.data.map(async (tx) => {
                if (tx.status == 'applied' && tx.parameter) {
                  if (['conclude_auction','bid'].includes(tx.parameter.entrypoint) && this.auctionId == parseInt(tx.parameter.value)) {
                    load = true
                  }
                }
              }),
            )
            if (load) {
              await new Promise((resolve) => setTimeout(resolve, 500))
              this.getAuction()
            }
          }
        })
      }
    },
    async getAuction () {
      try{
        if (this.state.allTokens.length === 0 || this.loading) {
          await new Promise((resolve) => setTimeout(resolve, 1000))
          return this.getAuction()
        }

        this.state.log('getAuction')
        this.loading = true
        
        const resp = await axios({
            url:'/api/auctions',
            params: {
              auctionId: this.auctionId,
            },
          })

        this.auction = resp.data.auctions.length ? resp.data.auctions[0] : false

        const alias = find(this.state.aliases, (a) => {return a.address === this.auction.seller})
        if(alias) {
          this.auction.sellerAlias = alias.alias
        }
        const bidderAlias = find(this.state.aliases, (a) => {return a.address === this.auction.bidder})
        if(bidderAlias) {
          this.auction.bidderAlias = bidderAlias.alias
        }
        
        this.state.log(this.$route.params, this.auction)

        this.loading = false
      } catch (error) {
        console.error(error)
        this.loading = false
      }
    },
    bidComplete(auctionId, bidAmount) {
      const idx = findIndex(this.auctions, auc => auc.auctionId === auctionId)
      if (idx > -1) {
        this.auction.bidder = this.state.userAddress
        this.auction.bidderAlias = this.state.userAlias
        this.auction.bidAmount = bidAmount
        this.auction.bids++
        const countDownDate = moment(this.auction.endTimestamp).valueOf()
        var now = new Date().getTime()
        var distance = countDownDate - now
        if (distance < (1000*60*5) && !this.auction.dutch) {
          this.auction.endTimestamp = moment(this.auction.endTimestamp).add(5, 'minutes').toISOString()
        }
      }
    },
    async claimComplete() {
      this.$router.push("/me")
    },
  },
}
</script>
