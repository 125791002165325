<template>
  <div
    class="flex flex-col nes-container is-rounded text-center"
    :class="{'bg-indigo-100': auction.dutch}"
    style="padding: .5rem .5rem; max-width: 400px;"
  >
    <AddrIcon
      :address="auction.seller"
      :alias="auction.sellerAlias"
    /> is auctioning
    <a
      v-if="showLink"
      :href="`https://www.pixelpotus.com/auctions/${auction.auctionId}`"
      target="_blank"
      class="absolute top right-0 mr-3"
    ><fa 
      icon="external-link-alt" 
      class="ml-1 align-baseline"
    /></a>
    <div class="flex flex-col items-left text-left m-auto">
      <div
        v-for="(tok, okey) in auction.tokens"
        :key="okey"
        class="text-sm font-medium text-gray-900 p-1"
      >
        <span
          v-if="okey > 0"
          class="mb-5 text-gray-500"
        >
          and
        </span>
        <div class="flex items-center">
          <div
            class="flex-shrink-1"
            :class="{'w-10': auction.tokens.length > 1}"
          >
            <img
              class="squared-full"
              :src="tok.metadata.displayUri"
              alt=""
            >
          </div>
          <div class="ml-4">
            <div class="text-sm font-medium text-gray-900">
              {{ tok.metadata.name }}
            </div>
            <div class="text-sm text-gray-500">
              {{ tok.metadata.rarity }} x{{ tok.amount }}
            </div>
            <div class="text-sm text-gray-500">
              Token ID: {{ tok.tokenId }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="p-1 mt-auto">
    <div class="grid grid-flow-col">
      <div
        class="text-sm text-left font-medium text-gray-900 p-1"
      >
        <span class="bg-green-100 text-green-800">{{ auction.dutch ? minBid.toFixed(3) : state.convertFromMutez(auction.bidAmount, 1) }}tez</span>
        <br>
        <span class="text-xs text-gray-500">price</span>
      </div>
      <div
        class="text-sm text-right font-medium text-gray-900 p-1"
      >
        <span class="bg-black text-gray-300 p-1 rounded">{{ countdown }}</span>
        <br>
        <span class="text-xs text-gray-500">{{ auction.dutch && !metReserve ? 'until decrease' : 'time left' }}</span>
      </div>
    </div>
    <hr class="p-1">
    <div
      class="text-sm text-center font-medium text-gray-900 p-1"
    >
      High Bidder:<br> <span v-if="auction.bidder !== auction.seller">
        <AddrIcon
          :address="auction.bidder"
          :alias="auction.bidderAlias"
        />
      </span> <span
        v-else
        class="text-gray-500"
      >No Bids</span>
    </div>
    <div
      v-if="(countdown != 'ENDED' && countdown != 'CONCLUDED') && state.userAddress !== auction.seller"
      class="mt-1 flex rounded-md shadow-sm pt-2 mb-2 w-full"
    >
      <input
        v-model="newBid"
        type="number"
        :step="auction.bidStep/1000000"
        :min="minBid"
        :disabled="loading"
        class="text-center p-2 focus:ring-indigo-500 focus:border-indigo-500 flex-1 block rounded-none rounded-l-md sm:text-sm border-2 border-gray-500 border-solid w-1/2"
      >
      <button
        class="nes-btn is-primary w-1/2"
        :disabled="loading"
        @click="bid"
      >
        <span v-if="loading && !buying">
          <fa 
            icon="cog" 
            class="animate-spin h-5 w-5 mr-3"
          />{{ auction.dutch ? 'Buying' : 'Bidding' }}
        </span>
        <span v-else>
          {{ auction.dutch ? 'Buy Now' : 'Bid' }}
        </span>
      </button>
    </div>
    <div
      v-if="state.userAddress === auction.seller || ((countdown == 'ENDED' || countdown == 'CONCLUDED') && state.userAddress === auction.bidder)"
      class="mt-1 flex rounded-md shadow-sm pt-2 mb-2 w-full"
    >
      <button
        class="nes-btn is-primary w-full"
        :disabled="loading"
        @click="conclude"
      >
        <span v-if="loading">
          <fa 
            icon="cog" 
            class="animate-spin h-5 w-5 mr-3"
          />{{ auction.seller == state.userAddress ? 'Ending Auction' : 'Withdrawing' }}
        </span>
        <span v-else>
          {{ auction.seller == state.userAddress ? 'End Auction' : 'Withdraw' }}
        </span>
      </button>
    </div>
    <span
      class="text-center text-gray-700 text-xs"
    >
      {{ auction.bids }} bids
    </span>
    <div
      v-if="auction.buyNow > 0 && (countdown != 'ENDED' && countdown != 'CONCLUDED') && state.userAddress !== auction.seller"
      class="mt-1 flex rounded-md shadow-sm pt-2 mb-2 w-full"
    >
      <button
        class="nes-btn w-full"
        :disabled="loading"
        @click="bid(true)"
      >
        <span v-if="loading && buying">
          <fa 
            icon="cog" 
            class="animate-spin h-5 w-5 mr-3"
          />Buying...
        </span>
        <span v-else>
          Buy Now for {{ state.convertFromMutez(auction.buyNow,2) }}xtz
        </span>
      </button>
    </div>
    <hr class="pb-1 mt-1">
    <span
      class="text-center text-gray-500 text-xs"
    >
      <span v-if="countdown == 'ENDED' || countdown == 'CONCLUDED'">
        Auction ended. Please withdraw tokens.
      </span>
      <span v-else-if="auction.dutch">
        1st bidder wins. Start price {{ state.convertFromMutez(auction.bidStart,2) }}xtz decreases by {{ state.convertFromMutez(auction.bidStep,2) }}xtz {{ duration }} (min {{ state.convertFromMutez(auction.reserve,2) }}xtz).
      </span>
      <span v-else>
        High bidder wins. Bids within 5 mins of end will extend auction by 5 mins.
      </span>
    </span>
  </div>
</template>

<script>

import { OpKind } from '@taquito/taquito'
import moment from 'moment'

import AddrIcon from './AddrIcon'

/* eslint-disable vue/require-default-prop */
export default {
  name: 'Auction',
  components: {
    AddrIcon,
  },
  props: {
    auction: Object,
    bidComplete: Function,
    claimComplete: Function,
    showLink: Boolean,
  },
  data () {
    return {
      state: this.$root.$data.state,
      countdown: 'LOADING',
      newBid: 0,
      loading: false,
      buying: false,
      interval: null,
      minBid: 0,
      metReserve: false,
    }
  },
  computed: {
    duration () {
      let humanized = moment.duration(this.auction.roundLen, 'seconds').humanize()
      if(humanized.indexOf('an ') !== -1) {
        humanized = 'once ' + humanized
      } else {
        humanized = 'every ' + humanized
      }
      return humanized
    },
  },
  async mounted() {
    if (!this.interval) {
      this.interval = setInterval(this.tick, 1000)
    }
  },
  async unmounted() {
    clearInterval(this.interval)
  },
  methods: {
    async tick () {
      let metReserve = false
      if (this.auction.dutch) {
        const since_start = moment().unix() - moment(this.auction.startTimestamp).unix()
        const periods = Math.trunc(since_start / this.auction.roundLen)
        let cur_price = this.auction.bidStart - (this.auction.bidStep * periods)
        if (cur_price < this.auction.reserve) {
          metReserve = true
          this.metReserve = true
          cur_price = this.auction.reserve
        }
        if (this.auction.seller !== this.auction.bidder) {
          cur_price = this.auction.bidAmount
        }
        this.minBid = cur_price / 1000000
      } else {
        this.minBid = (this.auction.bidAmount + this.auction.bidStep) / 1000000
      }
      if (this.newBid < this.minBid) this.newBid = this.minBid
      

      let countDownDate = moment(this.auction.endTimestamp)
      if (this.auction.dutch && !metReserve) {
        const since_start = moment().unix() - moment(this.auction.startTimestamp).unix()
        const periods = Math.trunc(since_start / this.auction.roundLen) + 1
        countDownDate = moment(this.auction.startTimestamp).add(periods*this.auction.roundLen, 'seconds')
      }
      countDownDate = countDownDate.valueOf()

      // Get today's date and time
      var now = new Date().getTime()

      // Find the distance between now and the count down date
      var distance = countDownDate - now

      // If the count down is finished, write some text
      if (this.auction.dutch && this.auction.seller !== this.auction.bidder) {
        this.countdown = "CONCLUDED"
      } else if (distance < 0) {
        this.countdown = "ENDED"
      } else {
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24))
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)
        this.countdown = `${days}`.padStart(2, '0') + ":" + `${hours}`.padStart(2, '0') + ":" + `${minutes}`.padStart(2, '0') + ":" + `${seconds}`.padStart(2, '0')
      }
    },
    async bid (buyNow) {
      this.loading = true
      this.buying = (buyNow == true)
      console.log(this.newBid, buyNow, this.auction)
      try{
        const opts = {}
        opts.amount = buyNow == true ? this.auction.buyNow : this.state.convertToMutez(parseFloat(Number(this.newBid).toFixed(6)))
        opts.mutez = true

        let transactions = []
        transactions.push({
              kind: OpKind.TRANSACTION,
              ... this.state.ppAuction.methods.bid(this.auction.auctionId).toTransferParams(),
              ...opts,
            })

        const batch = await this.state.tezos.wallet.batch(transactions)
        let op = await batch.send()
        await op.confirmation(1)

        this.loading = false
        this.buying = false
        this.bidComplete(this.auction.auctionId, opts.amount)
        this.newBid = this.minBid
      } catch(e) {
        this.loading = false
        this.buying = false
        // this.trading = false
        console.error('Unable to place bid', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push("/")
        }
      }
    },
    async conclude () {
      this.loading = true
      try{
        let transactions = []
        transactions.push({
              kind: OpKind.TRANSACTION,
              ... this.state.ppAuction.methods.conclude_auction(this.auction.auctionId).toTransferParams(),
            })
        const batch = await this.state.tezos.wallet.batch(transactions)
        let op = await batch.send()
        await op.confirmation(1)

        if (this.auction.bidder == this.state.userAddress) {
          setTimeout(()=>{this.state.loadMyTokens(true, true)}, 15000)
        }
        this.loading = false
        this.claimComplete(this.auction.auctionId)
      } catch(e) {
        this.loading = false
        // this.trading = false
        console.error('Unable to place bid', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push("/")
        }
      }
    },
  },
}
</script>
