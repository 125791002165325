<template>
  <div class="grid grid-cols-1 gap-12 items-center justify-between text-left mt-12 pb-32 overflow-hidden">
    <div
      class="flex"
    >
      <div class="hidden lg:inline flex-none w-300 pr-5">
        <img
          src="../assets/column1-pixel.png"
          width="300"
        >
      </div>
      <div class="flex-grow bg-brown-dark p-10 nes-container is-rounded">
        <h1 class="text-3xl text-center pb-5">
          The Treasury
        </h1>
        <div
          v-if="loading"
          class="text-center"
        >
          Loading...
        </div>
        <div v-else>
          <div
            v-if="payouts.length == state.allTokens.length/6"
            class="text-gray-800"
          >
            <div class="text-center text-2xl">
              All unique POTUSes have been minted and the treasury is decommissioned.
            </div>
          </div>
          <div v-else>
            <div class="grid grid-cols-2 text-gray-600">
              <div class="text-right">
                Balance:
              </div>
              <div class="text-left text-green-800 self-end">
                {{ state.convertFromMutez(balance) }}tez
              </div>
            </div>
            <div
              class="grid grid-cols-2 text-gray-600"
            >
              <div class="text-right">
                Next Payout:
              </div>
              <div class="text-left text-green-800 self-end">
                {{ state.convertFromMutez(balance * (payoutPercent/100)) }}tez
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden lg:inline flex-none w-300 pl-5">
        <img
          src="../assets/column1-pixel.png"
          width="300"
        >
      </div>
    </div>

    <hr>
    <SpeechBubble
      :img="state.images.jefferson"
      :show="showHelp"
      :potus="'Jefferson'"
      class="pb-32"
    >
      {{ fee }}% of claim & upgrade fees are reserved in the treasury.
      <br><br>
      Newly minted unique POTUS owners will each receive a one time {{ payoutPercent }}% payout until the final (45th) unique POTUS token is minted - the final will receive whatever is remaining in the treasury. Get it? <a
        href="#"
        class="text-blue-500 hover:text-blue-600"
        @click="toggleHelp"
      >Got it</a>
    </SpeechBubble>

    <div v-if="claimable.length && !loading">
      <h1 class="text-3xl text-center pb-5">
        Waiting to be claimed
      </h1>
      <div
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2"
      >
        <div
          v-for="(p, key) in claimable"
          :key="key"
          class="nes-container is-rounded"
        >
          <div class="flex text-center text-xs items-center">
            <div class="flex-shrink-0 w-16">
              <img
                class="w-16 squared-full"
                :src="p.metadata.displayUri"
                :alt="p.metadata.name"
              >
            </div>
            <div class="ml-4">
              <div class="text-base text-left text-gray-900">
                {{ p.metadata.name }}
                <button
                  class="text-sm uppercase p-4 nes-btn is-primary w-full bottom-1"
                  @click="claimTreasure(p.tokenId)"
                >
                  Claim Payout!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="payouts.length && !loading">
      <h1 class="text-3xl text-center pb-5">
        Past Payouts
      </h1>
      <div
        class="flex flex-col"
      >
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Time
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Owner
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      POTUS
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Payout
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    v-for="(p, pkey) in payouts"
                    :key="pkey"
                  >
                    <td class="px-6 py-4 whitespace-nowrap">
                      {{ p.timestamp }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <AddrIcon
                        :address="p.address"
                        :alias="p.alias"
                      />
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="flex-shrink-0 w-10">
                          <img
                            class="w-10 squared-full"
                            :src="p.token.metadata.displayUri"
                            alt=""
                          >
                        </div>
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                            <router-link
                              class="text-base font-medium"
                              :to="`/potus/${p.tokenId}`"
                            >
                              {{ p.token.metadata.name }}
                            </router-link> 
                            <!-- <span class="text-sm text-gray-500">{{ p.token.rarity }}</span> -->
                          </div>
                          <div class="text-sm text-gray-500">
                            Token ID: {{ p.tokenId }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      {{ state.convertFromMutez(p.payout) }}tez
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SpeechBubble
      :img="state.images.pierce"
      :show="claiming"
      :potus="'Pres. Pierce'"
      :is-left="false"
    >
      Enjoy this random POTUS gif while u wait. You can also go do other stuff without affecting the transaction. <br>
      <img
        :src="nextGif"
        width="300"
        class="m-auto"
      >
    </SpeechBubble>
  </div>
</template>

<script>

import { OpKind } from '@taquito/taquito'
import axios from 'axios'
import { find } from 'lodash'
import moment from 'moment'

import AddrIcon from '../components/AddrIcon'
import SpeechBubble from '../components/SpeechBubble'

export default {
  name: 'Treasury',
  components: {
    AddrIcon,
    SpeechBubble,
  },
  data () {
    return {
      state: this.$root.$data.state,
      balance: 0,
      fee: 20,
      payoutPercent: 25,
      payouts: [],
      loading: false,
      showHelp: true,
      filter: '',
      claimable: [],
      claiming: false,
      nextGif: null,
    }
  },
  async mounted() {
    const sh = localStorage.getItem('showTreasuryHelp')
    this.showHelp = sh ? JSON.parse(sh) : true
    await this.getStats()
    this.loadClaimable()
  },
  methods: {
    async refresh() {
      await this.getStats()
    },
    toggleHelp() {
      this.showHelp = false
      localStorage.setItem('showTreasuryHelp', JSON.stringify(false))
    },
    async loadClaimable() {
      if(!this.state.myTokensLoaded) {
        await new Promise((resolve) => setTimeout(resolve, 500))
        return this.loadClaimable()
      }
      this.claimable = this.state.myTokens.filter(tok => {
        const claimed = find(this.payouts, p => p.tokenId === tok.tokenId)
        return tok.tokenId > 6000 && !claimed
      })
    },
    async getStats () {
      this.loading = true
      try {
        const resp = await axios({
            url:'/api/treasury', 
          })
        this.balance = resp.data.treasury.balance
        this.fee = resp.data.treasury.fee
        this.payoutPercent = resp.data.treasury.payoutPercent
        this.payouts = resp.data.treasury.payouts.sort((a,b) => {
          if (moment(a.timestamp).isBefore(b.timestamp)) {
            return -1
          }
          return 1
        }).map(p => {
          p.address = p.address.substring(p.address.length - 10, p.address.length)
          p.timestamp = moment(p.timestamp).format('ll')
          p.token = find(this.state.allTokens, (tok) => {
            return tok.tokenId === p.tokenId
          })
          const alias = find(this.state.aliases, (a) => {return a.address === p.address})
          if(alias) {
            p.alias = alias.alias
          }
          return p
        })

        this.loading = false
      } catch (error) {
        this.loading = false
        // await new Promise((resolve) => setTimeout(resolve, 1000))
        // this.$router.go(0)
      }
    },
    async claimTreasure (tokenId) {
      this.loading = true
      this.claiming = true
      this.nextGif = this.state.gifs[Math.floor(Math.random() * this.state.gifs.length)]
      try {
        let operators = []
        operators.push({ 
            add_operator: { 
              owner: this.state.userAddress,
              operator: process.env.VUE_APP_PP_TREASURY,
              token_id: tokenId,
            },
          })
        let transactions = []
        transactions.push({
              kind: OpKind.TRANSACTION,
              ...this.state.ppMain.methods.update_operators(operators).toTransferParams(),
            })
        transactions.push({
              kind: OpKind.TRANSACTION,
              ... this.state.ppTreasury.methods.claim(tokenId).toTransferParams(),
            })
        const batch = await this.state.tezos.wallet.batch(transactions)
        let op = await batch.send()
        await op.confirmation(1)

        await this.getStats()

        await this.loadClaimable()

        this.loading = false
        this.claiming = false
      } catch (error) {
        this.loading = false
        this.claiming = false
      }
    },
  },
}
</script>
