<template>
  <img
    alt="Pixel Potus"
    src="../assets/potus.gif"
    class="mx-auto pb-12"
  >
  <div class="nes-container is-rounded bg-brown-dark">
    "We hold these truths to be self-evident, 
    that all NFTs are NOT created equal, that some are endowed 
    by their Creator with certain unalienable rights, that 
    among these are Life, Liberty and the pursuit of Pixelation."
  </div>
  <div class="grid grid-cols-1 md:grid-cols-1 gap-12 items-center justify-between text-left mt-12">
    <template v-if="!state.userAddress">
      <div class=" nes-container is-rounded flex items-center">
        <div class="flex-grow">
          <p>
            TLDR:
          </p>
          <p>
            Step 1: <a
              v-if="!state.isMobile"
              class="text-blue-light"
              href="https://thanoswallet.com/"
              target="_blank"
            >Create</a> <a
              v-if="state.isMobile"
              class="text-blue-light"
              href="https://kukai.app/"
              target="_blank"
            >Create</a> & then Connect your Tezos web wallet
            <button
              v-if="state.isMobile"
              class="self-center text-sm uppercase py-4 px-6 text-white bg-blue-dark hover:bg-blue-light border-2 border-gray-700 nes-btn is-primary h-20"
              @click="connectWallet"
            >
              Connect Wallet
            </button>
          </p>
          <p>
            Step 2: <a
              class="text-blue-light"
              href="https://www.coinbase.com/price/tezos"
              target="_blank"
            >Acquire some XTZ</a> to pay for transaction fees.
          </p>
          <p>
            Step 3: Claim, upgrade and trade POTUS NFTs
          </p>
          <p>
            Step 4: Profit!
          </p>
        </div>
        <div class="hidden sm:inline-flex flex-none align-middle">
          <button
            class="self-center text-sm uppercase py-4 px-6 text-white bg-blue-dark hover:bg-blue-light border-2 border-gray-700 nes-btn is-primary h-20"
            @click="connectWallet"
          >
            Connect Wallet
          </button>
        </div>
      </div>

      <div class="grid grid-cols-6 hidden sm:inline-grid">
        <div class="col-span-1">
          <img
            width="150"
            src="../assets/eagle-right.png"
          >
        </div>
        <div class="col-span-5 speechbg p-5 grid grid-cols-6">
          <div class="col-span-1" />
          <div class="col-span-5 p-5">
            PixelPotus
            <ul class="list-inside list-disc">
              <li class="text-green-800">
                is a digital collectable competition
              </li>
              <li>built on the Tezos blockchain</li>
              <li class="text-green-800">
                45 POTUSes with 6 different rarities
              </li>
              <li>custom artwork at each level</li>
              <li class="text-green-800">
                the first collectors to mint unique POTUSes win prizes
              </li>
              <li>
                <router-link to="/about">
                  Learn More
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="sm:hidden grid grid-cols-12">
        <div class="p-5 nes-balloon from-left col-span-12">
          PixelPotus
          <ul class="list-outside list-disc">
            <li class="text-green-400">
              is a digital collectable competition
            </li>
            <li>built on the Tezos blockchain</li>
            <li class="text-green-400">
              45 POTUSes with 6 different rarities
            </li>
            <li>custom artwork at each level</li>
            <li class="text-green-400">
              the first collectors to mint unique POTUSes win prizes
            </li>
            <li>
              <router-link to="/about">
                Learn More
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-span-2 -mt-6 -ml-1">
          <img
            width="75"
            src="../assets/eagle-right.png"
          >
        </div>
      </div>
      
      <hr>
      <h2 class="text-2xl text-center">
        How to get started
      </h2>

      <div class="video-container">
        <iframe
          class="m-auto"
          src="https://www.youtube-nocookie.com/embed/PMrYsM5zDBo"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>

      <SpeechBubble
        :img="state.images.nixon"
        :show="true"
        :potus="'Nixon'"
      >
        <div class="sm:hidden">
          You need a Tezos wallet - we like the Kukai web wallet - it works on mobile and makes interacting with PixelPotus and other apps easier-> <a
            class="text-blue-light"
            href="https://kukai.app/"
            target="_blank"
          >Kukai</a>
        </div>
        <div class="hidden sm:inline">
          You need a Tezos wallet - we like the Temple web wallet - its a chrome extension and makes interacting with PixelPotus and other apps easier-> <a
            class="text-blue-light"
            href="https://thanoswallet.com/"
            target="_blank"
          >Temple Wallet</a>
        </div>
      </SpeechBubble>

      <SpeechBubble
        :img="state.images.clinton"
        :show="true"
        :potus="'Clinton'"
        :is-left="false"
      >
        Once that's installed, follow the steps in the extension to create a shiny new wallet. Please backup your wallet seed and password. No one can help you if you lose them and you can lose access to your assets forever.
      </SpeechBubble>

      <SpeechBubble
        :img="state.images.kennedy"
        :show="true"
        :potus="'Kennedy'"
      >
        You will need a little bit of XTZ (Tezos) in your wallet to pay for gas fees on the network. Head over to your favorite exchange (<a
          class="text-blue-light"
          href="https://www.coinbase.com/price/tezos"
          target="_blank"
        >Coinbase</a>, <a
          class="text-blue-light"
          href="https://www.gemini.com/prices/tezos"
          target="_blank"
        >Gemini</a>, <a
          class="text-blue-light"
          href="https://www.binance.com/en/trade/XTZ_USDT"
          target="_blank"
        >Binance</a>) and grab a few XTZ, then send it to your new wallet address. Once you see the new XTZ balance show up in your wallet, you are ready to start collecting some PixelPOTUS!
      </SpeechBubble>

      <SpeechBubble
        :img="state.images.greatseal"
        :show="true"
        :potus="'Illuminati'"
        :is-left="false"
        class="pb-8"
      >
        Go to <a
          class="text-blue-light"
          href="https://www.pixelpotus.com"
        >Pixelpotus.com</a> and click the giant Connect Wallet button. From there you can start claiming free POTUSes, trading in the marketplace and upgrading to build your collection.
      </SpeechBubble>
    </template>
  </div>
  <div 
    v-if="state.userAddress" 
    class="grid grid-cols-1 md:grid-cols-3 gap-12 items-center justify-between mt-12 mb-24"
  >
    <div
      v-for="(b, i) in btns"
      :key="i"
    >
      <button
        :class="b.styles"
        class="uppercase py-4 px-6 border-2 border-gray-700 mb-2"
        @click="b.click"
      >
        {{ b.label }}
      </button>
      <p class="text-sm pt-5">
        {{ b.description }}
      </p>
    </div>
  </div>
  <div
    class="nes-container is-rounded bg-brown-dark overflow-ellipsis overflow-hidden"
  >
    Official PixelPotus Links 
    <br><br>
    Contract Address:
    <a
      v-if="state.isMobile"
      :href="`https://better-call.dev/mainnet/${state.contract}/operations`"
      target="_blank"
      class="sm:hidden overflow-ellipsis overflow-hidden"
    >{{ formattedAddr }}</a>
    <a
      v-else
      :href="`https://better-call.dev/mainnet/${state.contract}/operations`"
      target="_blank"
      class="overflow-ellipsis overflow-hidden"
    >{{ state.contract }}</a>
    <br><br>
    Twitter:<a
      href="https://twitter.com/PixelPotus"
      target="_blank"
      class="overflow-ellipsis overflow-hidden"
    >@PixelPotus</a>
    <br><br>
    Discord:<a
      href="https://discord.gg/CbdbvwtwkM"
      target="_blank"
      class="overflow-ellipsis overflow-hidden"
    >Invitation</a>
    <br><br>
    Telegram:<a
      href="https://t.me/pixelpotus"
      target="_blank"
      class="overflow-ellipsis overflow-hidden"
    >Invitation</a>
  </div>
  <div class="text-center w-full">
    <div class="p-5">
      <a
        href="https://www.producthunt.com/posts/pixelpotus?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-pixelpotus"
        target="_blank"
        class="m-auto"
      ><img
        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=299093&theme=dark"
        alt="PixelPotus - A president themed collectable card NFT game built on Tezos | Product Hunt"
        style="width: 250px; height: 54px;"
        width="250"
        height="54"
        class="m-auto"
      ></a>
      <span class="text-xs">(Check it out and spread the word!)</span>
    </div>
  </div>
</template>

<script>

import SpeechBubble from '../components/SpeechBubble'

export default {
  name: 'GetStarted',
  components: {
    SpeechBubble,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    connectWallet: Function,
  },
  data () {
    return {
      state: this.$root.$data.state,
      btns: [
        {
          label: 'claim',
          styles: 'nes-btn is-error',
          description: 'Snag a free common POTUS while they last',
          click: () => {this.$router.push("/claim")},
        },
        {
          label: 'upgrade',
          styles: 'nes-btn is-primary',
          description: 'Burn your common POTUSes for rarer ones',
          click: () => {this.$router.push("/me")},
        },
        {
          label: 'trade',
          styles: 'nes-btn is-warning',
          description: 'Exchange POTUSes with other constituents',
          click: () => {this.$router.push("/market")},
        },
      ],
    }
  },
  computed: {
    formattedAddr () {
      return this.state.contract.substring(0, 5) + '..' + this.state.contract.substring(this.state.contract.length - 5)
    },
  },
  methods: {
  },
}
</script>