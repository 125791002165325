<template>
  <div
    v-if="show"
    class="speechbubble flex"
  >
    <div
      v-if="isLeft"
      class="flex-shrink self-start"
    >
      <img
        v-if="img"
        class="speaker"
        :src="img"
      >
      <span
        v-if="potus.length"
        class="text-xs"
      >{{ potus }}</span>
    </div>
    <div
      class="flex-grow "
      :class="{'speechbg-rev': !isLeft, 'speechbg': isLeft}"
    >
      <slot>
        This will only be displayed if there is no content
        to be distributed.
      </slot>
    </div>
    <div
      v-if="!isLeft"
      class="flex-shrink self-start"
    >
      <img
        v-if="img"
        class="speaker"
        :src="img"
      >
      <span
        v-if="potus.length"
        class="text-xs"
      >{{ potus }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpeechBubble',
  props: {
    img: {
      type: Object,
      default: () => { return null },
    },
    potus: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    isLeft: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      state: this.$root.$data.state,
    }
  },
}
</script>

<style>
.speechbubble {
  font-size: 0.75rem;
  line-height: 1rem
}
.speaker {
  max-width: 4rem;
}
@screen md {
  .speechbubble {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .speaker {
    max-width: 6rem;
  }
}
</style>
