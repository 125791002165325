<template>
  <!--
    https://www.w3.org/TR/wai-aria-practices/#Listbox
    https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
  -->
  <div v-click-away="away">
    <div class="mt-1 relative">
      <button
        type="button"
        class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        @click="show = !show"
      >
        <span class="flex items-center">
          <span class="ml-3 block truncate text-sm sm:text-base">
            Select POTUSes
          </span>
        </span>
        <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <!-- Heroicon name: solid/selector -->
          <svg
            class="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </button>
      <transition
        enter-active-class=""
        enter-from-class=""
        enter-to-class=""
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ul
          v-show="show"
          class="absolute mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm z-10 border-2 border-black divide-y"
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-option-3"
        >
          <li>
            <input
              v-model="filter"
              type="text"
              class="w-full p-2 border-2 mt-1 focus:ring-blue-500 focus:border-blue-500 block shadow-sm text-xs sm:text-sm border-gray-300 rounded-none w-full"
              placeholder="filter..."
            >
          </li>
          <li
            v-for="(group, gIndex) in groups"
            id="listbox-option-0"
            :key="gIndex"
            class="text-gray-900 cursor-default select-none relative"
            :class="{'hidden': gIndex.length && gIndex.toLowerCase().indexOf(filter.toLowerCase()) === -1}"
            role="option"
            @click="groupSelected(gIndex)"
          >
            <div class="flex items-center hover:bg-indigo-200 py-2 pl-3 pr-9">
              <span class="text-xs sm:text-sm font-normal truncate">
                {{ gIndex }} 
                <fa 
                  v-if="selectedGroup != gIndex"
                  icon="chevron-right" 
                  class="text-blue-link hover:text-blue-hov h-5 w-5 ml-1"
                />
                <fa 
                  v-if="selectedGroup == gIndex"
                  icon="chevron-down" 
                  class="text-blue-link hover:text-blue-hov h-5 w-5 ml-1"
                />
              </span>
            </div>
            <ul
              v-show="selectedGroup == gIndex"
            >
              <li
                v-for="(tok, index) in group"
                id="listbox-option-0"
                :key="index"
                class="text-gray-900 hover:bg-indigo-200 cursor-default select-none relative py-2 pl-3 pr-9"
                role="option"
                @click.stop="itemSelected(tok);"
              >
                <div class="flex items-center">
                  <img
                    :src="tok.metadata.thumbnailUri"
                    alt=""
                    class="flex-shrink-0 w-6 square-full"
                  >
                  <span class="text-xs sm:text-sm font-normal ml-3 truncate">
                    {{ tok.metadata.shortName }}<span class="text-gray-500">({{ tok.balance ? tok.balance + `x ` : '' }}{{ tok.rarity }})</span>
                  </span>
                </div>
              </li>
            </ul>
          </li>
          
          <li v-if="tokens.length === 0">
            You have no POTUSes to trade.
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>

import { groupBy } from 'lodash'

/* eslint-disable vue/require-default-prop */
export default {
  name: 'TradeDropdown',
  props: {
      tokens: Array,
      label: String,
      itemSelected: Function,
      groupTokens: Boolean,
  },
  data () {
    return {
      show: false,
      selectedGroup: null,
      filter: '',
    }
  },
  computed: {
    groups () {
      return groupBy(this.tokens, (t) => t.metadata.name)
    },
  },
  methods: {
    away () {
      this.show = false
    },
    groupSelected (gIndex) {
      if (this.selectedGroup === gIndex) {
        this.selectedGroup = null
      } else {
        this.selectedGroup = gIndex
      }
    },
  },
}
</script>
