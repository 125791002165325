<template>
  <div class="grid grid-cols-1 md:grid-cols-1 gap-12 items-center justify-between text-left mt-12 pb-32">
    <h1 class="text-2xl text-center">
      Become a Patriot - Pick a Pack of POTUSes!
    </h1>
    
    <SpeechBubble
      :img="state.images.arthur"
      :show="showHelp && !claiming"
      :is-left="false"
      :potus="'Pres. Arthur'"
    >
      <div v-if="!confirmation">
        Limited supply! Become a patriot and pick a single pack of 10 commons of your POTUS of choice. <b>You can only pick ONE pack, so choose wisely!</b>
      </div>
      <div
        v-else
        class="text-xl"
      >
        Pack claimed! Check out your shiny new POTUS tokens in <router-link
          class=""
          to="/me"
        >
          your collection.
        </router-link>
      </div>
    </SpeechBubble>

    <div
      v-if="!initialized"
      class="text-center"
    >
      Loading...
    </div>
    <div
      v-if="claiming"
      class="text-center"
    >
      <fa 
        icon="cog" 
        class="animate-spin h-5 w-5 mr-3"
      />Please wait up to 1 minute for block confirmation.
    </div>
    <div
      v-if="initialized && !isPatriot && !claiming"
      class="text-left grid gap-5 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3"
    >
      <div
        v-for="(s, key) in series"
        :key="key"
        class="nes-container is-dark is-rounded"
      >
        <div class="flex items-center ">
          <div class="flex-shrink-0 sm:w-30">
            <img
              class="w-24 sm:w-40 square-full"
              :src="s.levels[0].metadata.displayUri"
              alt=""
            >
          </div>
          <div class="ml-2 pl-2">
            {{ s.seriesId }}. {{ s.levels[0].metadata.name }}
            <br>Packs: {{ s.packs }}/20 left
            <button
              class="text-sm uppercase p-4 nes-btn w-full bottom-1"
              :disabled="s.packs === 0 || !s.claimable"
              :class="{'is-disabled': s.packs === 0 || !s.claimable, 'is-primary': s.packs > 0 && s.claimable}"
              @click="buyPack(s.seriesId)"
            >
              <span v-if="s.packs === 0 || !s.claimable">Sold Out</span>
              <span v-else>Buy Pack ({{ state.convertFromMutez(claimPackPrice) }}tez)</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <SpeechBubble
      :img="state.images.pierce"
      :show="claiming"
      :potus="'Pierce'"
      :is-left="false"
    >
      Enjoy this random POTUS gif while u wait. You can also go do other stuff without affecting the transaction. <br>
      <img
        :src="nextGif"
        width="300"
        class="m-auto"
      >
    </SpeechBubble>

    <SpeechBubble
      :img="state.images.hayes"
      :show="isPatriot"
      :potus="'Pres. Hayes'"
    >
      Each constituent is allowed to purchase only a single pack, so you are maxed out. Thanks for your patronage!
    </SpeechBubble>

    <hr>
    <div v-if="patriots.length">
      <h1 class="text-3xl text-center pb-5">
        Patriots
      </h1>
      <div
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2"
      >
        <div
          v-for="(p, key) in patriots"
          :key="key"
          class="nes-container is-rounded"
          :class="{'is-dark': p.address === state.userAddress }"
        >
          <div class="flex text-center text-xs items-center">
            <div class="flex-shrink-0 w-16">
              <img
                class="w-16 squared-full"
                :src="p.token.metadata.displayUri"
                :alt="p.token.metadata.name"
              >
            </div>
            <div class="ml-4">
              <div class="">
                <AddrIcon
                  :address="p.address"
                  :alias="p.alias"
                />
                <span class=""> claimed a pack of</span>
              </div>
              <div class="text-gray-900">
                <router-link
                  class="text-base sm:text-sm xl:text-xl"
                  :to="`/potus/${p.tokenId}`"
                >
                  {{ p.token.metadata.name }}s
                </router-link> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import { find, findIndex } from 'lodash'

import AddrIcon from '../components/AddrIcon'
import SpeechBubble from '../components/SpeechBubble'

export default {
  name: 'Packs',
  components: {
    AddrIcon,
    SpeechBubble,
  },
  data () {
    return {
      state: this.$root.$data.state,
      claiming: false,
      confirmation: false,
      nextGif: null,
      getLoop: true,
      initialized: false,
      claimPackPrice: 4000000,
      series: this.$root.$data.state.stats ? this.$root.$data.state.stats.series : [],
      isPatriot: false,
      showHelp: true,
      patriots: [],
      offset: 0,
      limit: 5,
    }
  },
  computed: {
    pages () {
      if (!this.patriots) {
        return 1
      }
      let tot = parseInt((this.patriots.length / this.limit).toFixed(0))
      if (tot < (this.patriots.length / this.limit)) { tot++ }
      return tot
    },
  },
  async mounted() {
    this.state.log('packs mounted')
    if (this.state.userAddress === null) {
      this.$router.push("/")
    } else {
      const sh = localStorage.getItem('showPacksHelp')
      this.showHelp = sh ? JSON.parse(sh) : true

      this.state.loadStats(true)
      this.getLoop = true
      await this.getPacksStorage()
    }
  },
  async unmounted() {
    this.state.log('packs unmounted')
    this.getLoop = false
  },
  methods: {
    nextPage() {
      this.offset += this.limit
      if (this.offset >= this.patriots.length) {this.offset -= this.limit}
    },
    prevPage() {
      let offset = this.offset - this.limit
      if (offset < 0) {this.offset = 0}
      else {this.offset = offset}
    },
    toggleHelp() {
      this.showHelp = false
      localStorage.setItem('showPacksHelp', JSON.stringify(false))
    },
    async getPacksStorage () {
      if (!this.getLoop) {
        return
      }
      if (this.state.ppMain === null) {
        await new Promise((resolve) => setTimeout(resolve, 500))
        await this.getPacksStorage()
      } else {
        this.state.log('getPacksStorage')
        
        const claimResp = await axios({
          url:'/api/claimMeta', 
        })
        // this.state.log(claimResp.data)
        this.claimPackPrice = claimResp.data.meta.claimPackPrice
        this.isPatriot = find(claimResp.data.meta.patriots, (p) => {
          return p.address === this.state.userAddress
        })
        this.patriots = claimResp.data.meta.patriots.map(p => {
          p.tokenId = 1000 + parseInt(p.seriesId)
          p.token = find(this.state.allTokens, (t) => {
            return t.tokenId === p.tokenId
          })
          const alias = find(this.state.aliases, (a) => {return a.address === p.address})
          if(alias) {
            p.alias = alias.alias
          }
          return p
        })
        this.initialized = true
      }
    },
    async buyPack (seriesId) {
      if(this.state.balance < this.claimPackPrice) {
        alert(`You're wallet balance is ${this.state.convertFromMutez(this.state.balance)}. You need more tezzies to claim a pack!`)
        return
      }
      this.state.loading = true
      this.nextGif = this.state.gifs[Math.floor(Math.random() * this.state.gifs.length)]
      this.claiming = true
      this.confirmation = false
      if (this.state.userAddress === null) {
        await this.state.connectWallet()
      }
      try{
        let op = null
        const opts = {}
        opts.amount = this.claimPackPrice
        opts.mutez = true
        // opts.storageLimit = this.state.storageLimit

        op = await this.state.ppMain.methods.claim_pack(seriesId).send(opts)

        await op.confirmation(1)
        this.claiming = false
        this.state.loading = false
        this.confirmation = true
        this.isPatriot = true

        const tokenId = 1000+parseInt(seriesId)
        const token = find(this.state.allTokens, (tok) => {
          return tok.tokenId === tokenId
        })
        this.patriots.push({
          tokenId,
          token,
          address: this.state.userAddress,
          alias: this.state.userAlias,
          seriesId,
        })

        const idx = findIndex(this.state.myTokens, mt => mt.tokenId === tokenId)
        if (idx > -1) {
          this.state.myTokens[idx].balance += 10
        } else {
          this.state.myTokens.push({ ...token, balance: 10, upgrading: false })
        }
        this.state.sortTokens(this.state.sortBy, true)

        setTimeout(()=>{this.state.loadMyTokens(true, true)}, 15000)
      } catch(e) {
        this.state.loading = false
        this.claiming = false
        console.error('Unable to claim pack', e)
        if (e.name && e.name === 'BeaconWalletNotInitialized') {
          this.state.userAddress = null
          this.$router.push("/")
        }
      }
    },
  },
}
</script>