
import './index.css'
import './nes.min.css'
import 'vue2-animate/dist/vue2-animate.min.css'

import { createApp } from 'vue'
import VueClickAway from "vue3-click-away"
import VueClipboard from 'vue3-clipboard'

import { FontAwesomeIcon } from "@/plugins/font-awesome"

import App from './App.vue'
import router from './router'

const app = createApp(App)
    
app.use(router)
    .use(VueClickAway)
    .use(VueClipboard, {
        autoSetContainer: true,
        appendToBody: true,
      })
    .component("fa", FontAwesomeIcon)
    .mount('#app')
